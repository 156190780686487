import Vue from 'vue'
import ElementUI from 'element-ui';
import './theme/index.css';
import App from './App.vue';
import http from '@/utils/request'

Vue.config.productionTip = false

Vue.use(ElementUI);

// 挂载全局
Vue.prototype.$http = http

new Vue({
  render: h => h(App),
}).$mount('#app')
