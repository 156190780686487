<template>
    <div class="flexCol" style="height:230px;padding:0 30px;">
        <el-form ref="form" :model="form">
            <el-form-item>
                <el-input v-model="form.phone" oninput="value=value.replace(/[^\d]/g,'')" placeholder="手机号" maxlength="11"
                    clearable style="font-size:18px;">
                    <span slot="prepend" style="color:#333333;">+86</span>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.code" oninput="value=value.replace(/[^\d]/g,'')" placeholder="4位数字验证码" maxlength="4"
                    style="font-size:18px;">
                    <el-button slot="append" style="font-size:14px;color:#333333;" @click="handleGetCodeAction">
                        {{ codeButtonTitle }}</el-button>
                </el-input>
            </el-form-item>
        </el-form>
        <span v-if="errorMsg.length" style="color:red;">{{ errorMsg }}</span>
        <el-button type="primary" style="margin-top:35px;" @click="handleLoginAction">登录 / 注册</el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                phone: '',
                code: ''
            },
            codeButtonTitle: '点击获取验证码',
            getCodeTime: 60,
            errorMsg: ''
        }
    },
    methods: {
        handleGetCodeAction() {
            if (this.timer) {
                return;
            }
            let phone = this.form.phone
            if (phone.length != 11) {
                this.errorMsg = "手机号错误"
                return;
            }
            this.errorMsg = ''
            this.getCodeActionSuccess()
            this.$http.post("/weapp/SendSMSCode", { phone: phone }).then((response) => {
                if (response.status == 200) {
                    const responseData = response.data
                    if (responseData.code == 0) {
                        this.$message.success('发送成功');
                    } else if (responseData.code == 1) {
                        this.$message.error('操作频繁，请稍后再试');
                    } else if (responseData.code == 2) {
                        this.$message.error('发送错误，请重试');
                    }
                }
            });


        },
        getCodeActionSuccess() {
            this.codeButtonTitle = `${this.getCodeTime--}秒后重新获取`
            this.timer = setInterval(() => {
                this.codeButtonTitle = `${this.getCodeTime--}秒后重新获取`
                if (this.getCodeTime < 0) {
                    this.codeButtonTitle = '点击获取验证码'
                    this.getCodeTime = 60
                    this.timer && clearInterval(this.timer)
                    this.timer = null
                }
            }, 1000);
        },
        handleLoginAction() {
            const phone = this.form.phone
            const code = this.form.code
            if (phone.length < 11) {
                this.errorMsg = "手机号错误"
                return
            }
            if (code.length < 4) {
                this.errorMsg = "验证码错误"
                return
            }
            this.errorMsg = ''

            this.$http.post("/weapp/WebLogin", { phone: phone, code: code }).then((response) => {
                if (response.status == 200) {
                    const responseData = response.data
                    if (responseData.code == 0) {
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        });
                        // localStorage.setItem('phone', this.form.phone)
                        localStorage.setItem('userInfo', JSON.stringify(responseData.data))

                        this.$emit('on-success', responseData.data)
                        this.form.phone = ''
                        this.form.code = ''
                    } else if (responseData.code == 1) {
                        this.$message.error('验证码错误');
                    } else if (responseData.code == 2) {
                        this.$message.error('登录环境发生变化，请重新登录');
                    }
                }
            });


        },
    }
}
</script>
<style scoped>
@import "../style/common.css";
</style>