<template>
    <div class="flexCol" style="height:530px;padding:0 30px;align-items: center;">
        <span style="font-size:46px;">打开微信扫一扫下载App</span>
        <span style="font-size:34px;color:red;">可100%免费无限制使用</span>
        <img style="height:300px;width:300px;margin-top:50px;" src="../assets/download.png" />
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
<style scoped>
@import "../style/common.css";
</style>