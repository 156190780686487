import axios from 'axios'
import HOST from '@/config/EnvConfig';

const http = axios.create({
    // baseURL: window.SITE_CONFIG['apiURL'],
    baseURL: HOST,
    timeout: 1000 * 180,
    withCredentials: true
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    // config.headers['Accept-Language'] = Cookies.get('language') || 'zh-CN'
    // config.headers['token'] = Cookies.get('token') || ''

    config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    config.headers = {
        'Content-Type': 'application/json' //配置请求头
    }
    // 默认参数
    // var defaults = {}
    // 防止缓存，GET请求默认带_t参数
    if (config.method === 'get') {
        config.params = {
            ...config.params,
            ...{ '_t': new Date().getTime() }
        }
    }
    
    return config
}, error => {
    return Promise.reject(error)
})


/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
    // if (response.data.code === 401 || response.data.code === 10001) {
        // clearLoginInfo()
        // router.replace({ name: 'login' })
    //     return Promise.reject(response.data.msg)
    // }
    return response
}, error => {
    console.error(error)
    return Promise.reject(error)
})

export default http