<template>
  <el-container style="height: 100%">
    <el-header class="flexRow borderColorWidth" style="align-items: center; border-bottom-style: solid">
      <img class="logo" alt="Vue logo" src="../assets/logo.png" />
      <span style="font-size:26px;margin-left:10px;font-weight:500;">传图识字</span>
    </el-header>
    <el-container>
      <el-aside v-if="isExpanded" class="flexCol" style="width: 200px;  align-items: center;">
        <div style="display: flex;flex-direction: row;padding: 10px;width:180px;" @click="isExpanded = false">
          <div style="display: flex;flex:1;"></div>
          <i class="el-icon-s-fold"></i>
        </div>
        <img class="hederImg" alt="Vue logo" src="../assets/avatar.png" />
        <div v-if="userInfo" class="flexCol" style="align-items: center;">
          <span style="margin-top: 10px">{{ userInfo.phone }}</span>
          <span v-if="userInfo.isVip" style="margin-top: 5px;color:red;font-size:12px;">{{ userInfo.expiredDate[1] > 0 ?
            '永久有效' : `${userInfo.expiredDate}到期` }}</span>
          <span v-if="!userInfo.isVip && userInfo.expiredDate" style="margin-top: 5px;color:#bbbbbb;font-size:12px;">会员已于
            {{ userInfo.expiredDate }} 过期</span>
          <span v-if="userInfo.restCount > 0" style="margin-top: 5px;color:red;font-size:12px;">会员识别剩余{{
            userInfo.restCount }}次</span>
        </div>
        <el-button v-else round size="mini" style="margin-top: 20px" @click="loginDialogVisible = true">登录</el-button>
        <span v-if="userInfo == null"
          style="margin-top: 10px;color:#bbbbbb;font-size:12px;text-align: center;">若已在手机端充值会员<br>请点击登录</span>
        <el-menu style="width: 200px;margin-top:10px;" default-active="0" class="el-menu-vertical-demo"
          @select="handleMenuSelect">
          <el-menu-item index="0">
            <i class="el-icon-document"></i>
            <span slot="title">文字识别</span>
          </el-menu-item>
          <!-- <el-menu-item index="2">
            <i class="el-icon-setting"></i>
            <span slot="title">表格识别</span>
          </el-menu-item> -->
          <el-menu-item index="1">
            <i class="el-icon-download"></i>
            <span slot="title">下载App</span>
          </el-menu-item>
        </el-menu>
        <el-button v-if="userInfo" round size="mini" style="margin-top: 30px" @click="handleLogoutAction">退出登录
        </el-button>
      </el-aside>
      <el-aside v-else class="flexCol" style="width: 70px;  align-items: center;">
        <div style="display: flex;flex-direction: row;padding: 10px;width:50px;" @click="isExpanded = true">
          <div style="display: flex;flex:1;"></div>
          <i class="el-icon-s-unfold"></i>
        </div>
        <el-menu style="width: 70px;margin-top:10px;" default-active="0" class="el-menu-vertical-demo"
          @select="handleMenuSelect">
          <el-menu-item index="0">
            <i class="el-icon-document"></i>

          </el-menu-item>
          <!-- <el-menu-item index="2">
            <i class="el-icon-setting"></i>
            <span slot="title">表格识别</span>
          </el-menu-item> -->
          <el-menu-item index="1">
            <i class="el-icon-download"></i>

          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <RecognizeTextPage @on-refresh-count="handleCountRefresh"></RecognizeTextPage>
      </el-main>
    </el-container>
    <el-dialog title="手机号登录" :visible.sync="loginDialogVisible" width="450px" :close-on-click-modal="false">
      <LoginPage @on-success="handleLoginSuccessAction"></LoginPage>
    </el-dialog>
    <el-dialog :visible.sync="downloadAppVisible" width="750px" :close-on-click-modal="false">
      <DownloadApp @on-download="downloadAppVisible = true"></DownloadApp>
    </el-dialog>
  </el-container>
</template>

<script>
import RecognizeTextPage from "./RecognizeTextPage.vue";
import LoginPage from "./LoginPage.vue";
import DownloadApp from "./DownloadApp.vue";
export default {
  name: "MainPage",
  components: { RecognizeTextPage, LoginPage, DownloadApp },
  data() {
    return {
      isExpanded: true,
      loginDialogVisible: false,
      downloadAppVisible: false,
      userInfo: null
    }
  },
  watch: {
    isExpanded: {
      handler: function (val, oldVal) {
        localStorage.setItem('isExpanded', val)
      },
    }
  },
  mounted() {
    window.addEventListener("beforeunload", this.beforeUnloadHandler, false)
    let userInfo = localStorage.getItem('userInfo')
    this.userInfo = JSON.parse(userInfo)

    const status = localStorage.getItem('isExpanded');
    this.isExpanded = status == null ? true : (status == 'true' ? true : false)
    if (userInfo) {
      this.tokenLogin();
    } else {
      this.isExpanded = true
    }
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler, false)
  },
  methods: {
    beforeUnloadHandler(e) {
      e.returnValue = '确定离开此页面？'
      return '确定离开此页面？'
    },
    handleMenuSelect(index) {
      console.log(index)
      if (index == 1) {
        this.downloadAppVisible = true
      }
    },
    handleLoginSuccessAction(userInfo) {
      this.loginDialogVisible = false
      this.userInfo = userInfo
    },
    handleLogoutAction() {

      this.$confirm('确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('userInfo')
        this.userInfo = null
        this.$message({
          type: 'success',
          message: '已退出登录!'
        });

      }).catch(() => {

      });
    },
    handleCountRefresh(restCount) {
      if (this.userInfo && restCount < this.userInfo.restCount) {
        this.userInfo.restCount = restCount
      }
    },
    tokenLogin() {
      this.$http.post("/weapp/WebLogin", { phone: this.userInfo.phone, token: this.userInfo.token }).then((response) => {
        if (response.status == 200) {
          const responseData = response.data
          if (responseData.code == 0) {
            // this.$message({
            //   message: '登录成功',
            //   type: 'success'
            // });
            // localStorage.setItem('phone', this.form.phone)
            localStorage.setItem('userInfo', JSON.stringify(responseData.data))
            this.userInfo = responseData.data

          } else if (responseData.code == 2) {
            this.$message.error('登录环境发生变化，请重新登录');
            // this.handleLogoutAction();
            localStorage.removeItem('userInfo')
            this.userInfo = null
            this.isExpanded = true
          }
        }
      });
    }
  }

};
</script>

<style scoped>
@import "../style/common.css";

.logo {
  width: 40px;
  height: 40px;
}

:deep().el-menu {
  border-right: 0 !important;
}

:deep().el-main {
  padding: 20px 0 20px 0 !important;
}

.hederImg {
  width: 80px;
  height: 80px;
  border: 1px solid #eeeeee;
  border-radius: 40px;
  margin-top: 40px;
}

.borderColorWidth {
  border-color: #eeeeee;
  border-width: 1px;
}
</style>
