
import md5 from 'js-md5';

function getSign(params){
    let keys = Object.keys(params)
    keys.sort()
    let sign = ''
    keys.map(key => {
        if (key !== 'sign') {
            sign+= `${key}=${params[key]??''}&`
        }
    })
    sign = sign.slice(0, sign.length-1)
    // console.log(sign)
    sign = md5(sign).toUpperCase()
    // console.log(sign)
    return sign
    
}

export default {getSign}