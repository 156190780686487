<template>
  <div class="flexRow" style="height: 100%">
    <div class="lineSeparator" style="margin-right: 30px"></div>
    <el-upload ref="uploader" class="upload-demo" drag action="https://upload.qiniup.com" :data="qiniuData"
      :show-file-list="false" multiple :limit="12" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess"
      :on-error="handleUploadError" :on-exceed="handleExceedError">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text" style="padding:0 10px 0 10px;">将图片拖到此处，或<em>点击上传</em><br>或复制图片后，在此页面Ctrl+V即可上传</div>
      <div class="el-upload__tip" slot="tip">
        只能上传jpg/png文件，且每次不超过12张<br><br>
        小tips：<br>
        &emsp;1. 电脑上的图片，可以直接复制到此页面，<br>
        &emsp;&emsp;Ctrl+V即可上传。<br>
        &emsp;2. 手机上的图片，可下载App进行识别。<br>
        &emsp;3. 在手机App可免费、无限制图片识别。<br>
        &emsp;4. 充值会员后识别准确率更高。<br>
        &emsp;5. 充值会员后PC、平板、手机多端通用。<br>
        <br><br>
        扫码下载App<br>
        <img style="height:150px;width:150px;margin-top:10px;" src="../assets/download.png" />
      </div>
    </el-upload>
    <div class="lineSeparator" style="margin: 0 30px 0 30px"></div>
    <div class="flexRow" style="flex-wrap: wrap; align-content: flex-start">
      <CardItem v-for="(item, index) in recognizeFileList" :item="item" :key="index"
        @onCheck="handleCheckAction(item, index)" @onSelect="handleSelectAction(item, index)"
        @onRetry="handleRetryAction(item, index)" @onDeleteItem="handleDeleteItemAction(item, index)"></CardItem>
    </div>
    <el-button v-if="recognizeFileList.length" type="primary" round style="position: absolute; right: 60px; bottom: 60px"
      @click="handleMergeAction">合并识别结果</el-button>
    <el-button v-if="recognizeFileList.length" type="info" round style="position: absolute; right: 220px; bottom: 60px"
      @click="handleClearListAction">清除列表</el-button>
    <el-dialog :visible.sync="resultDialogVisible" :fullscreen="dislogFullscreen" width="70%" top="5vh"
      :close-on-click-modal="false" :close-on-press-escape="false" :before-close="resultDialogBeforeClose">
      <el-row slot="title" type="flex">
        <el-button @click="handleChangeDialog">{{ dislogFullscreen ? '点击窗口展示' : '点击全屏展示'
        }}</el-button>
        <div style="display: flex;flex:1;height: 1px;flex-direction: row;"></div>
        <el-button type="primary" style="margin-right: 60px;" @click="handleCopyAllAction">全部复制</el-button>
      </el-row>
      <div class="flexRow" :style="{ height: dislogFullscreen ? '85vh' : '80vh' }" @mousemove="handleDrag"
        @mouseup="endDrag">
        <div style="overflow-y: scroll;background-color: white;" :style="{ width: leftWidth + 'px' }">
          <div v-for="(  item, index  ) in   resultShowImageList  " :key="index">
            <el-image :src="item" :preview-src-list="resultShowImageList" style="pointer-events:none;"></el-image>
            <el-divider v-if="index < resultShowImageList.length - 1" content-position="center">分割线</el-divider>
          </div>
        </div>
        <div class="splitter" @mousedown="startDrag" @mouseup="endDrag">
          <img style="width:10px;height:10px;pointer-events:none;" src="../assets/drag.png" />
        </div>
        <textarea style="flex:1;resize: none; font-size: 18px" v-model="resultShowText"></textarea>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as imageConversion from "image-conversion";
import CardItem from "./CardItem.vue";

import * as qiniu from "qiniu-js";
import utils from "@/utils/utils";

import FingerprintJS from '@fingerprintjs/fingerprintjs'


export default {
  name: "RecognizeTextPage",
  components: { CardItem },
  data() {
    return {
      dislogFullscreen: false,
      dialogWidth: 0,
      leftWidth: 0,
      prefixUrl: "",
      qiniuData: {
        token: "",
        key: "",
      },
      recognizeFileList: [],
      resultDialogVisible: false,
      resultShowImageList: [],
      resultShowText: "",
      mergeFileList: [],
      userInfo: null,
      uuid: null,
      repeatIndex: 1,
    };
  },
  mounted() {
    this.onRequest()
    let userInfo = localStorage.getItem('userInfo')
    this.userInfo = JSON.parse(userInfo)
    this.createFingerprint();
    this.listenPasteEvent();

    // 监听窗口大小变化事件，更新窗口大小数据
    // window.addEventListener("resize", this.updateWindowDimensions);
    // 在组件挂载后获取一次窗口大小

    this.dialogWidth = window.innerWidth * 0.7
    this.leftWidth = window.innerWidth * 0.7 / 2;

    this.loadEditCache();
  },
  watch: {
    resultShowText: {
      handler: function (val, oldVal) {
        // console.log(val)
        let res = []
        if (this.recognizeFileList.length) {
          this.recognizeFileList.map(item => {
            for (let i = 0; i < this.resultShowImageList.length; i++) {
              const element = this.resultShowImageList[i];
              if (item.fileUrl === element) {
                res.push(item.url)
              }
            }
          })
        } else {
          res = this.resultShowImageList
        }
        if (res.length > 0) {
          localStorage.setItem('editCache', JSON.stringify({ resultShowText: val, resultShowImageList: res }))
        }
      },
    },
    recognizeFileList: {
      handler: function (val, oldVal) {
        if (val.length == 0) {
          this.repeatIndex = 1;
        }

      }
    },
  },
  methods: {

    createFingerprint() {
      const fpPromise = FingerprintJS.load()
      fpPromise.then(fp => {
        return fp.get()
      }).then(result => {
        // console.log(result.visitorId.toUpperCase())
        let uuid = result.visitorId.toUpperCase()
        this.uuid = uuid
      })
    },
    listenPasteEvent() {
      const that = this;
      document.body.addEventListener('paste', function (event) {
        let items = event.clipboardData && event.clipboardData.items
        // console.log(items)
        let fileList = [];
        if (items && items.length) {
          // 检索剪切板items
          for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
              //这里拿到了图片的流文件
              let file = items[i].getAsFile()
              if (file !== null) {
                fileList.push(file)
              }
            }
          }
        }
        if (fileList.length > 0) {
          // console.log(fileList)
          if (fileList.length > 12) {
            fileList = fileList.slice(0, 12)
            this.$message.error('上传图片每次不能超过12张')
          }
          fileList.map(file => {
            that.$refs.uploader.handleStart(file);
          })
          that.$refs.uploader.submit();
        }
      });
    },
    renameDuplicateFile(name) {
      let newName = `${name}`
      let index = this.recognizeFileList.findIndex((item) => item.name == newName)
      const nameParts = newName.split('.')
      const extension = nameParts.pop()
      while (index > -1) {
        newName = nameParts.join('.') + `(${this.repeatIndex})` + '.' + extension
        index = this.recognizeFileList.findIndex((item) => item.name == newName)
        this.repeatIndex++
      }
      return newName
    },
    loadEditCache() {
      const editCache = localStorage.getItem('editCache')
      if (editCache) {
        this.$confirm('检测到有未完成的编辑内容，是否恢复？')
          .then(_ => {
            const editCacheObj = JSON.parse(editCache)
            this.resultShowText = editCacheObj.resultShowText
            this.resultShowImageList = editCacheObj.resultShowImageList
            this.resultDialogVisible = true
          })
          .catch(_ => {
            localStorage.removeItem('editCache')
          });

      }
    },
    onRequest() {
      this.$http.get("/weapp/AndroidQNToken").then((response) => {
        if (response.status == 200) {
          //console.log(response.data);
          this.qiniuData.token = response.data.uptoken;
          this.prefixUrl = response.data.prefix;
        }
      });
    },
    async handleBeforeUpload(file) {
      // console.log("压缩前111", file);
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg";
      const isPNG = file.type === "image/png";
      if (!isJPG && !isPNG) {
        this.$message.error("图片只能是 JPG/PNG 格式!");
        return false;
      }
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > 20) {
        this.$message.error("图片大小不能超过 20MB!");
        return false;
      }
      for (let i = 0; i < this.recognizeFileList.length; i++) {
        const element = this.recognizeFileList[i];
        if (element.name == file.name && element.size == file.size) {
          this.$message.error(`图片“${file.name}”已在识别列表中`);
          return false;
        }
      }
      let _this = this;
      let key = this.generateImageKey() + "_" + file.size + "_" + file.name;
      let _URL = window.URL || window.webkitURL;
      let newFile = {
        uid: file.uid,
        name: file.name,
        showName: _this.renameDuplicateFile(file.name),
        size: file.size,
        raw: file,
        status: 0,
        key: key,
        fileUrl: _URL.createObjectURL(file),
        url: "",
        result: [],
      };
      this.recognizeFileList.push(newFile);
      return new Promise((resolve, reject) => {
        // let _URL = window.URL || window.webkitURL;
        // 这里需要计算出图片的长宽
        let img = new Image();
        img.onload = function () {
          file.width = img.width; // 获取到width放在了file属性上
          file.height = img.height; // 获取到height放在了file属性上
          let isLt2M = fileSize > 0.5; // 判定图片大小是否小于0.5MB
          let valid = img.width > 2000; // 图片宽度大于2000
          ////console.log("压缩前", file);
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          if (valid || isLt2M) {
            // 大小在500k以下，宽度1000以下
            try {
              imageConversion
                .compressAccurately(file, {
                  size: 500,
                  width: img.height / img.width > 1 ? 1000 : 2000,
                })
                .then((res) => {
                  ////console.log("压缩后", res);
                  _this.qiniuData.key = key;
                  resolve(res);
                });
            } catch {
              reject(file);
            }
          } else {
            _this.qiniuData.key = key;
            resolve(file);
          }
        }; // 需要把图片赋值
        img.src = _URL.createObjectURL(file);
      });
    },
    generateImageKey() {
      let first = new Date().valueOf().toString();
      let second = "";
      for (let i = 0; i < 5; i++) {
        let tmpRandom = Math.floor(Math.random() * 10);
        second += tmpRandom.toString();
      }
      return first + second;
    },
    sleep(millisecond) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, millisecond)
      })
    },
    async handleUploadSuccess(response, file, fileList) {
      let userInfo = localStorage.getItem('userInfo')
      this.userInfo = JSON.parse(userInfo)
      // {"status":"success/ready/uploading/fail","name":"IMG_4DC1BF732A66-1.jpeg","size":542416,"percentage":100,"uid":1663922697622,"raw":{"uid":1663922697622,"width":828,"height":1792},"response":{"hash":"FtTASm00QGCYkthdfOob5TO9rw5t","key":"1663922697632_542416_IMG_4DC1BF732A66-1.jpeg"}}
      ////console.log(`handleUploadSuccess response: ${JSON.stringify(response)}`);
      ////console.log(`handleUploadSuccess file: ${JSON.stringify(file)}`);
      ////console.log(`handleUploadSuccess fileList: ${JSON.stringify(fileList)}`);
      ////console.log(this.recognizeFileList);
      await this.sleep(300);
      const obj = this.recognizeFileList.find((item) => item.uid == file.uid);
      const objIndex = this.recognizeFileList.indexOf(obj);
      obj.url = this.prefixUrl + encodeURIComponent(response.key);
      ////console.log(obj.url)
      let params = {
        mediaUrl: obj.url,
        phone: this.userInfo == null ? this.uuid : this.userInfo.phone,
        recognizeType: 0,
        languageType: "",
        isAccurate: 0,
        detectDirection: 1,
        timestamp: new Date().valueOf()
      };

      let sign = utils.getSign(params)
      params['sign'] = sign
      this.$http
        .post("/weapp/RecognizeEngineWeb", params)
        .then((response) => {
          // console.log(response);
          if (response.status == 200) {
            if (response.data.code == 0) {
              obj.status = 2;
              obj.result = response.data.data;
              this.$emit('on-refresh-count', response.data.data.restCount);
            } else if (response.data.code == 104) {
              obj.status = 1;
              this.$message.error(response.data.info)
              this.$emit('on-download');
            } else {
              obj.status = 1;
              this.$message.error(response.data.info)
            }
          } else {
            obj.status = 1;
          }
          this.$set(this.recognizeFileList, objIndex, obj);
          //console.log(this.recognizeFileList);
        })
        .catch((err) => {
          //console.log(err);
          obj.status = 1;
          this.$set(this.recognizeFileList, objIndex, obj);
          //console.log(this.recognizeFileList);
        });
    },
    handleUploadError(err, file, fileList) {
      //console.log(`handleUploadError response: ${JSON.stringify(err)}`);
      //console.log(`handleUploadError file: ${JSON.stringify(file)}`);
      //console.log(`handleUploadError fileList: ${JSON.stringify(fileList)}`);
      const obj = this.recognizeFileList.find((item) => item.uid == file.uid);
      const objIndex = this.recognizeFileList.indexOf(obj);
      obj.status = 1;
      this.$set(this.recognizeFileList, objIndex, obj);
    },
    handleCheckAction(item, index) {
      // console.log(item);
      //console.log(index);
      this.resultShowImageList = [item.fileUrl];
      let text = "";
      item.result.result.forEach((obj) => {
        text += obj.content + "\n";
      });
      this.resultShowText = text;
      this.resultDialogVisible = true;
    },
    handleSelectAction(item, index) {
      //console.log(item);
      //console.log(index);
      item.status = item.status == 2 ? 3 : 2;
      this.$set(this.recognizeFileList, index, item);
      if (item.status == 2) {
        const index = this.mergeFileList.indexOf(item);
        if (index > -1) {
          this.mergeFileList.splice(index, 1);
        }
      } else if (item.status == 3) {
        this.mergeFileList.push(item);
      }
    },
    handleRetryAction(item, index) {
      //console.log(item);
      //console.log(index);
      item.status = 0;
      this.$set(this.recognizeFileList, index, item);
      if (item.url.length) {
        this.handleUploadSuccess({ key: item.key }, item.raw, [item.raw]);
        return;
      }
      const _this = this;
      let putExtra = {};
      let config = { useCdnDomain: true };
      let observable = qiniu.upload(
        item.raw,
        item.key,
        _this.qiniuData.token,
        putExtra,
        config
      );
      let error = function (err) {
        //console.log("上传出错", err);
        _this.handleUploadError(err, item.raw, [item.raw]);
      };
      let complete = function (res) {
        //console.log(res);
        _this.handleUploadSuccess(res, item.raw, [item.raw]);
      };
      let progress = function (response) { };
      let subObject = {
        next: progress,
        error: error,
        complete: complete,
      };
      observable.subscribe(subObject);
      let subscription = observable.subscribe(subObject);
    },
    handleDeleteItemAction(item, index) {
      this.recognizeFileList.splice(index, 1)
    },
    handleExceedError(files, fileList) { },
    handleMergeAction() {
      let _this = this;
      let imageList = [];
      let allText = "";
      let fileList = _this.mergeFileList.length > 0
        ? _this.mergeFileList
        : _this.recognizeFileList;

      fileList.map((item) => {
        if (item.status > 1) {
          imageList.push(item.fileUrl);
          let text = "";
          item.result.result.forEach((obj) => {
            text += obj.content + "\n";
          });
          allText += text + "\n\n\n";
        }
      });

      _this.resultShowImageList = imageList;
      _this.resultShowText = allText;
      _this.resultDialogVisible = true;
    },
    handleClearListAction() {
      this.recognizeFileList = []
    },
    handleCopyAllAction() {
      navigator.clipboard.writeText(this.resultShowText).then(() => {
        this.$message.success('复制成功')
      })
    },
    startDrag(event) {
      // console.log(event)
      this.dragging = true;
      this.startX = event.clientX;
      this.initialLeftWidth = this.leftWidth;
    },
    handleDrag(event) {
      if (this.dragging) {
        const deltaX = event.clientX - this.startX;
        const newLeftWidth = this.initialLeftWidth + deltaX;
        const dialogWidth = this.dialogWidth / (this.dislogFullscreen ? 0.7 : 1);
        if (newLeftWidth < dialogWidth * 0.2 || newLeftWidth > dialogWidth * 0.8) {
          this.dragging = false;
          return;
        }
        // 更新左侧区域宽度
        this.leftWidth = newLeftWidth;
      }
    },
    endDrag() {
      if (this.dragging) {
        this.dragging = false;
      }
    },
    handleChangeDialog() {
      this.dislogFullscreen = !this.dislogFullscreen
      if (this.dislogFullscreen) {
        this.leftWidth /= 0.7;
      } else {
        this.leftWidth *= 0.7;
      }
    },
    resultDialogBeforeClose(done) {
      this.$confirm('关闭后编辑内容将会清除，确认关闭？')
        .then(_ => {
          localStorage.removeItem('editCache')
          done();
        })
        .catch(_ => { });
    }
  },


};
</script>

<style scoped>
@import "../style/common.css";

:deep().el-upload {
  width: 100%;
  height: 30%;
  min-height: 200px;
}

:deep().el-upload .el-upload-dragger {
  width: 100%;
  height: 100%;
}

.lineSeparator {
  width: 1px;
  background-color: #eeeeee;
}

.splitter {
  width: 10px;
  cursor: col-resize;
  background-color: #ddd;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>