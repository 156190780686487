<template>
  <!-- v-for="(item, index) in recognizeFileList" :key="index" -->
  <div class="cardSize" style="
      height: 220px;
      margin-left: 30px;
      margin-top: 30px;
      border: 1px solid #dedede;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
    ">
    <div class="cardSize" style="position: relative;">
      <el-image class="cardLayer" fit="contain" :src="item.fileUrl"></el-image>
      <div class="cardLayer flexCol" style="justify-content: space-around; align-items: center" :style="{
        backgroundColor:
          item.status < 3 ? 'rgba(0, 0, 0, 0.4)' : 'rgba(15, 148, 246, 0.4)',
      }">
        <p v-if="item.status == 0" style="color: white">加载中...</p>
        <el-button v-if="item.status > 1" size="medium" style="background-color: #ba55d3; color: white; border-width: 0"
          @click="handleCheckAction">查看
        </el-button>
        <el-button v-if="item.status > 1" size="medium" style="
          margin: 0 0 0 0;
          background-color: #0f94f6;
          color: white;
          border-width: 0;
        " @click="handleSelectAction">{{ item.status == 2 ? "选择" : "反选" }}</el-button>
        <el-button v-if="item.status == 1" size="medium" style="
          margin: 0 0 0 0;
          background-color: #ea9641;
          color: white;
          border-width: 0;
        " @click="handleRetryAction">重试</el-button>
        <el-button v-if="item.status == 1" size="medium" style="
          margin: 0 0 0 0;
          background-color: #ff0000;
          color: white;
          border-width: 0;
        " @click="handleDeleteItemAction">删除</el-button>
      </div>
    </div>
    <p class="line-limit-length">{{ item.showName }}</p>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    handleCheckAction() {
      this.$emit('onCheck')
    },
    handleSelectAction() {
      this.$emit('onSelect')
    },
    handleRetryAction() {
      this.$emit('onRetry')
    },
    handleDeleteItemAction() {
      this.$emit('onDeleteItem')
    },
  }
};
</script>

<style scoped>
.cardSize {
  width: 150px;
  height: 170px;
}

.cardLayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.line-limit-length {
  width: 130px;
  height: 45px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 14px;
}
</style>