<template>
  <div id="app">
    <MainPage></MainPage>
    <div class="flex-row jc-center ai-center" style="position: absolute;left:10px;bottom: 0px;height: 25px;">
      <p style="color:#aaaaaa;font-size:14px;">联系方式：597238072@qq.com&nbsp;&nbsp;|&nbsp;&nbsp;公司地址：陕西省西安市</p>
      <a style="text-decoration: none;color:#aaaaaa;font-size:14px;" target="_blank"
        href="https://beian.miit.gov.cn">&nbsp;&nbsp;|&nbsp;&nbsp;陕ICP备20008115号</a>
    </div>
  </div>
</template>

<script>
import MainPage from './components/MainPage.vue'

export default {
  name: 'App',
  components: {
    MainPage
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0;
}

html,
body,
#app {
  height: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}



.ai-center {
  align-items: center;
}

.jc-center {
  justify-content: center;
}
</style>
